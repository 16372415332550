<template>
  <body-simple>
    <template #header>
      <h2 class="text-xl font-bold">پرداخت نا موفق</h2>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2 class="text-lg font-bold text-center p-3 bg-red-500 text-red-50">
          سفارش : {{ pay.id }}
        </h2>
        <div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">مقدار</div>
            <div class="font-bold text-left">{{ $number.format(pay.amount) }}</div>
          </div>
        </div>
        <div class="text-lg font-bold text-center p-3 bg-red-500 text-red-50">
          پرداخت ناموفق
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            :to="{ name: 'Panel', params: { id: $store.state.user.id } }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="cyan"
          >
            داشبورد
          </Button>
        </div>
        <div class="flex-1 mx-1">
          <Button
            :to="{ name: 'Wallet', params: { id: $store.state.user.id } }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="teal"
          >
            کیف پول من
          </Button>
        </div>
      </div>
    </template>
  </body-simple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
// import MnrSelect from "@/components/mnr/MnrSelect.vue";
// import MnrNumber from "@/components/mnr/MnrNumber.vue";
// import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    // MnrSelect,
    // MnrNumber,
    // MnrPassword,
    Button,
    BodySimple,
  },
  data() {
    return {
      pay: {},
    };
  },
  computed: {},
  mounted() {
    let $this = this;
    $this.errors = {};
    $this.disabaledForm = true;

    $this.$axios
      .get("/api/charges/" + $this.$route.params.payId, {
        params: { id: $this.$store.state.user.id },
      })
      .then(function (response) {
        $this.pay = response.data.pay;
        if (response.data.pay.status >= 100) {
          $this.$router.push({
            name: "WalletChargePayRes",
            params: { id: $this.$store.state.user.id, payId: response.data.pay.id },
          });
        }
      })
      .catch(function (error) {
        $this.errors = error.data;
        $this.disabaledForm = false;
        if (error.status == 406) {
          $this.$router.push("/panel");
        }
      });
  },
};
</script>
